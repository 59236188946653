import { Component, EventEmitter, Output } from '@angular/core';

import { CacheService } from '../../../../shared/services/cache.service';
import { BaseMenuItemComponent } from '../base-menu-item.component';
import { MenuItemChild } from '../models/menu-item-child.model';
import { MenuUtilsService } from '../services/menu-utils.service';
import { MenuSharedService } from '../services/menu.shared.service';
import { Global } from './../../../../shared/constants/global.constant';

@Component({
  selector: 'antecipacoes-menu-item',
  templateUrl: '../templates/menu-item.html',
  styleUrls: ['../scss/menu-item.scss'],
})
export class AntecipacoesMenuItem extends BaseMenuItemComponent {
  menuCollapsed: boolean;
  isMobile: boolean = false;
  @Output() onClickSelect = new EventEmitter<any>();
  @Output() onClickItem = new EventEmitter<void>();

  constructor(
    menuSharedService: MenuSharedService,
    private cacheService: CacheService,
    menuUtilsService: MenuUtilsService
  ) {
    // Título, Array de Filhos, SVG, Ativo (sim ou não), altura da lista quando os subitems estiverem à mostra
    super(
      'Antecipação',
      'antecipacoes',
      [],
      `<svg width="16" height="20" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6.33331H2.66667V7.66665H4V6.33331ZM6.66667 6.33331H5.33333V7.66665H6.66667V6.33331ZM9.33333 6.33331H8V7.66665H9.33333V6.33331ZM10.6667 1.66665H10V0.333313H8.66667V1.66665H3.33333V0.333313H2V1.66665H1.33333C0.593333 1.66665 0.00666666 2.26665 0.00666666 2.99998L0 12.3333C0 13.0666 0.593333 13.6666 1.33333 13.6666H10.6667C11.4 13.6666 12 13.0666 12 12.3333V2.99998C12 2.26665 11.4 1.66665 10.6667 1.66665ZM10.6667 12.3333H1.33333V4.99998H10.6667V12.3333Z" fill="#A0CFF8"/>
      </svg>`,
      false,
      false,
      '0px',
      menuSharedService,
      menuUtilsService
    );

    this.cacheService.getRouterGuardData().subscribe((res) => {
      const existsSellers = res.data.seller_amount > 0;
      const existsActiveSeller = res.data.sellers.some(
        (seller) => seller.seller_status.id === Global.VENDEDOR_ATIVO
      );
      const existsBatchAnticipation = res.data.batch_anticipation_amount > 0;
      if (existsSellers && existsActiveSeller) {
        this.children.push(
          new MenuItemChild(
            'Cartão de Crédito',
            '/pages/antecipacoes/antecipacao-transacao',
            false,
            true
          )
        );
      }

      if (existsBatchAnticipation) {
        this.children.push(
          new MenuItemChild(
            'Plano de Saúde',
            '/pages/antecipacoes/antecipacao-fatura',
            false,
            true
          )
        );
      }
    });

    this.menuSharedService.getSubItemsOf().subscribe((title) => {
      if (title === this.title && !this.showChildren) {
        this.showChildren = true;
        this.heightWhenShowingChildren = menuUtilsService.calculateSubmenuHeight(
          this.children
        );
      } else {
        this.showChildren = false;
        this.heightWhenShowingChildren = '0px';
      }
    });
  }

  async ngOnInit() {
    if (window.innerWidth <= 500 || screen.width <= 500) {
      this.isMobile = true;
    }

    this.menuSharedService.getMenuCollapsed().subscribe((menuCollapsed) => {
      this.menuCollapsed = menuCollapsed;
      this.heightWhenShowingChildren =
        menuCollapsed || this.isMobile ? '0px' : this.heightWhenShowingChildren;

      if (this.menuCollapsed && this.showChildren) {
        this.showChildren = false;
      }
    });
  }

  override toggleItemChildren() {
    super.toggleItemChildren();
    this.onClickSelect.emit({ title: this.title, children: this.children });
    this.isMobile = window.innerWidth <= 500 || screen.width <= 500 ? true : false;
  }

  override menuItemClicked() {
    super.menuItemClicked();
    this.onClickItem.emit();
  }
}
