import { Injectable } from '@angular/core';
import { RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { Oauth2TokenService } from './oauth2-token.service';

@Injectable()
export class DefaultRequestOptionsService extends RequestOptions {
  constructor(private oauth2Token: Oauth2TokenService) {
    super();
  }

  override merge(options?: RequestOptionsArgs): RequestOptions {
    let headers = options.headers || new Headers();
    headers.set('Authorization', `Bearer ${this.oauth2Token.token}`);
    headers.set('Content-Type', 'application/json');
    options.headers = headers;
    return super.merge(options);
  }
}
