import { BaseRoutes } from './base.routes.constants';
import { UnidadeRoutes } from './unidade.routes.constants';
import { ContatoRoutes } from './contato.routes.constants';
import { ContaRoutes } from './conta.routes.constants';
import { CadastroCompletoRoutes } from './cadastro-completo.routes.constants';
import { MedicoRoutes } from './medico.routes.constants';
import { PlanoRoutes } from './plano.routes.constants';

export class OrganizacaoRoutes extends BaseRoutes {
  public UNIDADE: UnidadeRoutes;
  public CONTATO: ContatoRoutes;
  public CONTA: ContatoRoutes;
  public CADASTRO_COMPLETO: CadastroCompletoRoutes;
  public MEDICO: MedicoRoutes;
  public PLANO: PlanoRoutes;

  constructor(
    route: string,
    unidadeRoute: string,
    contatoRoute: string,
    contaRoute: string,
    cadastroCompletoRoute: string,
    medicoRoute: string,
    planoRoute: string
  ) {
    super(route);
    this.UNIDADE = new UnidadeRoutes(this.appendPath(this.BASE, unidadeRoute));
    this.CONTATO = new ContatoRoutes(this.appendPath(this.BASE, contatoRoute));
    this.CONTA = new ContaRoutes(this.appendPath(this.BASE, contaRoute));
    this.CADASTRO_COMPLETO = new CadastroCompletoRoutes(
      this.appendPath(this.BASE, cadastroCompletoRoute)
    );
    this.MEDICO = new MedicoRoutes(this.appendPath(this.BASE, medicoRoute));
    this.PLANO = new PlanoRoutes(this.appendPath(this.BASE, planoRoute));
  }

  override preAppend(route: string) {
    super.preAppend(route);
    this.UNIDADE.preAppend(route);
    this.CONTATO.preAppend(route);
    this.CONTA.preAppend(route);
    this.CADASTRO_COMPLETO.preAppend(route);
    this.MEDICO.preAppend(route);
    this.PLANO.preAppend(route);
  }
}
