import { BaseRoutes } from './base.routes.constants';

export class DashboardRoutes extends BaseRoutes {
  constructor(route: string) {
    super(route);
  }

  override preAppend(route: string) {
    super.preAppend(route);
  }
}
