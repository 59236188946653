export const environment = {
  production: false,
  title: 'iClinic Pay - Organização',
  zoop_gateway_id: '90ba19c4-e347-4126-b797-74c3b5626622',
  name: 'dev',
  cookieConsent: 'https://cookie-consent.dev.iclinicpay.com.br/cookieConsent.js',
  api: {
    client_id: '2',
    client_secret: 'dhGLOnQlLZhDPJmRaNXXJcAOEcDZnqMtm7VGpc5t',
    base_url: 'https://api.dev.iclinicpay.com.br/',
    base_api: 'https://api.dev.iclinicpay.com.br/api/v1/',
    base_api_v2: 'https://api.dev.iclinicpay.com.br/api/v2/',
    base_api_v3: 'https://api.dev.iclinicpay.com.br/api/v3/',
    receipt: 'https://receipt.dev.iclinicpay.com.br/',
  },
  snowplow_collector_url: '',
  snowplow_app_id: '',
  intercom_app_id: 'gl163exs',
  minimum_installment_value: 5,
  featureFlags: {
    modalityScreenAvaliable: false,
    maintenanceAlert: false,
    maintenancePage: false,
  },
};
